.header {
    width: 100%;
    height: 60px;
    background: #F1F7FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: fixed;
    z-index: 999;
    padding-left: 1%;
    padding-right: 1%;
}
.header375 {
    width: 100%;
    height: 8vh;
    background: #F1F7FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: fixed;
    z-index: 999;
    padding-left: 3%;
    padding-right: 3%;
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.logo {
    background: transparent 0% 0% no-repeat padding-box;
    height: 36px;
    width: 107px;
}

.botonHeader {
    width: 291px;
    height: 58px;
    border-radius: 20px;
    opacity: 1;
    background: none;
    padding-left: 25px;
    color: #7A86A1;
}

.botonHeader:hover {
    background: #0089FF 0% 0% no-repeat padding-box;
    color: white;
}
.botonHeaderNovedadesNuevo {
    width: 291px;
    height: 58px;
    border-radius: 20px;
    opacity: 1;
    background: none;
    padding-left: 25px;
    color: #7A86A1;
}

.botonHeaderNovedadesNuevo:hover {
    background: #F99946 0% 0% no-repeat padding-box;
    color: white;
}

.botonHeaderPas {
    padding: 5px;
    border-radius: 10px;
    opacity: 1;
    background: #0089FF 0% 0% no-repeat padding-box;
    color: white;
}

.botonHeaderPas:hover {
    color: white;
}


.botonHeaderCerrarSesionPas {
    width: 140px;
    height: 50px;
    border-radius: 20px;
    border: none;
    opacity: 1;
    padding: 20px;
    background: #0089FF 0% 0% no-repeat padding-box;
    color: white;
}



.textoNombreHeader {
    font-size: 22px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.btnAjustes {
    border: none;
    background: none;
    padding-left: 25px;
}

.ajustes {
    font-size: 23px;
    color: #7A86A1;
}

.botonCerrarSesion {
    width: 130px;
    height: 31px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.cerrarSesion {
    border: none;
    background: none;
    letter-spacing: 0px;
    color: #7A86A1;
    opacity: 1;
}


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
.labelBotonesNavegacion {
    color: #7A86A1;
    font-size: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-wrap: nowrap;
    margin: 0;
}


.btnFlechaNovedadesAtras {
    background-color: red;
    color: white;
    border: none;
    border-radius: 10px;
    width: 70px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
.btnFlechaNovedadesSiguiente {
    background-color: #0089FF;
    color: white;
    border: none;
    border-radius: 10px;
    width: 70px;
    margin-left: 5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.btnFlechaNovedadesAtrasMed {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 10px;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
.btnFlechaNovedadesSiguienteMed {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 10px;
    width: 100px;
    margin-left: 5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.btnFlechaGestionAtras {
    background-color: rgba(42, 42, 253, 0.637);
    color: white;
    border: none;
    border-radius: 20px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnFlechaGestionSiguiente {
    background-color: blue;
    color: white;
    border: none;
    border-radius: 20px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.btnFlechaMediacionAtras {
    background-color: rgb(211, 152, 42);
    color: white;
    border: none;
    border-radius: 20px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnFlechaMediacionSiguiente {
    background-color: orange;
    color: white;
    border: none;
    border-radius: 20px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnDatosNavegacion {
    position: relative;
    cursor: pointer;
    border: none;
    background: none;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-container .tooltip-text {
    visibility: hidden;
    min-width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 100%; /* Ajusta esto según donde quieras el tooltip */
    left: 50%;
    margin-left: -80px; /* Centra el tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  