@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.novedadesTicketContainer2025 {
    width: 100%;
    height: 70px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBD8D8;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
}

.novedadesTicketContainer2025Abierto {
    width: 100%;
    height: 240px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBD8D8;
    border-radius: 8px;
    opacity: 1;
    margin-bottom: 24px;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 10px;
}

.tituloDelTicket2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 23px;
    color: #262626;
    align-self: center;
    margin: 0;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.tituloDelTicket2025:hover {
    color: inherit;
    text-decoration: none;
}

.exp-nro {
    margin-right: 12px;
}

.vtoContainer {
    width: 120px;
    height: 32px;
    background: #F6FAFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBE4F0;
    border-radius: 4px;
    opacity: 1;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vtoTexto {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}

.ultModContainer {
    width: 180px;
    height: 32px;
    background: #F6FFF8 0% 0% no-repeat padding-box;
    border: 1px solid #DCF0DB;
    border-radius: 4px;
    opacity: 1;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ultModTexto {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}

.segundoBloqueTexto {
    font: normal normal normal 19px/29px Poppins;
    color: #505050;
    width: 17%;
}

.quienEnvio {
    font: normal normal 600 20px/30px Poppins;
    color: #404040;
    width: 20%;
}

.fechaQuienEnvio {
    font: normal normal normal 19px/29px Poppins;
    color: #505050;
    width: 17%;
}
.fechaQuienEnvioFresh {
    font: normal normal normal 19px/29px Poppins;
    color: #505050;
    width: 60%;
}
.asuntoQuienEnvio {
    font: italic normal normal 19px/29px Poppins;
    color: #505050;
}



.estado2025 {
    width: 140px;
    height: 29px;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.estadoA2025 {
    background-color: #BDBAD4;
}

.estadoB2025 {
    background-color: #001EA3;
}

.estadoC2025 {
    background-color: #1235D4;
}

.estadoD2025 {
    background-color: #126CDF;
}

.estadoE2025 {
    background-color: #1C95C7;
}

.estadoF2025 {
    background-color: #15E0B7;
}

.estadoG2025 {
    background-color: #1AFF66;
}

.estadoH2025 {
    background-color: orange;
}

.estadoI2025 {
    background-color: red;
}

.estadoDefault2025 {
    background-color: gray;
}



.custom-checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Contenedor visual del checkbox */
  .checkbox-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px; 
    height: 20px;
    border: 2px solid #F98D4E; 
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.8s ease;
  }
  
  /* Efecto hover */
  .checkbox-label:hover {
    border-color: #e87c3e;
  }
  
  .custom-checkbox:checked + .checkbox-label {
    background-color: #F98D4E;
    border-color: #F98D4E;
  }
  
  /* Check animado */
  .custom-checkbox:checked + .checkbox-label::after {
    content: "";
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.2s ease;
  }
  