.acordeonContainer {
    height: 600px;
}

.headerPresentAcordeonNaranja {
    width: 100%;
    height: 47px;
    background: orange;
    border-radius: 26px;
    border: none;
}

.headerPresentAcordeonNaranja:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-top: 1px solid #0019bb38;
    border-bottom: none;
}

.headerSuccessAcordeon {
    width: 100%;
    height: 47px;
    background: #d6faec 0% 0% no-repeat padding-box;
    border-radius: 26px;
    border: none;
}

.headerSuccessAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-top: 1px solid #0019bb38;
    border-bottom: none;
}

.headerPresentAcordeon {
    width: 100%;
    height: 47px;
    background: #e9f3ff8c 0% 0% no-repeat padding-box;
    border-radius: 26px 26px;
    border: none;
}

.headerPresentAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-top: 1px solid #0019bb38;
    border-bottom: none;
}

.headerFutureAcordeon {
    width: 100%;
    height: 47px;
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border-radius: 26px 26px;
    border: none;
}

.headerFutureAcordeon:not(.collapsed) {
    border-radius: 26px 26px 0px 0px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-top: 1px solid #0019bb38;
    border-bottom: none;
}

.bodyAcordeon {
    width: 100%;
    height: 280px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 26px 26px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-bottom: 1px solid #0019bb38;
    margin-top: 0;
    opacity: 1;
}

.bodyAcordeon2 {
    width: 100%;
    height: 420px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 26px 26px;
    border-left: 1px solid #0019bb38;
    border-right: 1px solid #0019bb38;
    border-bottom: 1px solid #0019bb38;
    margin-top: 0;
    opacity: 1;
}

.tituloAcordeon {
    font-size: 15px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.estadoCont {
    height: 28px;
    width: 100px;
    font-size: 10px;
    border-radius: 10px;
    text-overflow: ellipsis;
    color: white;
    text-align: center;
}

.estadoAMediacion {
    background-color: #bdbad4;
}

.estadoBMediacion {
    background-color: #001ea3;
}

.estadoCMediacion {
    background-color: #1235d4;
}

.estadoDMediacion {
    background-color: #126cdf;
}

.estadoEMediacion {
    background-color: #1c95c7;
}

.estadoFMediacion {
    background-color: #15e0b7;
}

.estadoGMediacion {
    background-color: #1aff66;
}

.estadoHMediacion {
    background-color: orange;
}

.estadoIMediacion {
    background-color: red;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap");

.miniExpContainerMediacion {
    height: 51px;
    background: transparent linear-gradient(86deg, #f9b035 0%, #f98c4e 53%, #f96767 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    padding-left: 17.5px;
    color: #ffffff;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0;
    line-height: 51px;
}

.tituloMediacion {
    font-size: 18px;
    line-height: 22px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #0722a8;
    opacity: 1;
}

.tituloMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #0722a8;
    opacity: 1;
}

.textMiniTablaMediacion {
    font-size: 13px;
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722a8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722a8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.textMiniTablaMediacion1366 {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0722a8;
    opacity: 1;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
}

.subTextMiniTablaMediacion {
    font-size: 14px;
    font-weight: bold;
    color: #0722a8;
}

.subTextMiniTablaMediacion1366 {
    font-size: 12px;
    font-weight: bold;
    color: #0722a8;
}

.contenedorPlanillaMediacion {
    margin-bottom: 5px;
}

.estadoExpA {
    color: white;
    background-color: #bdbad4;
}

.estadoExpB {
    color: white;
    background-color: #001ea3;
}

.estadoExpC {
    color: white;
    background-color: #1235d4;
}

.estadoExpD {
    color: white;
    background-color: #126cdf;
}

.estadoExpE {
    color: white;
    background-color: #1c95c7;
}

.estadoExpF {
    color: white;
    background-color: #15e0b7;
}

.estadoExpG {
    color: white;
    background-color: #1aff66;
}

.estadoExpH {
    color: white;
    background-color: orange;
}

.estadoExpH2 {
    color: white;
    background-color: orange;
}

.estadoExpI {
    color: white;
    background-color: red;
}

.estadoExp375 {
    width: 74px;
    height: 18px;
    border-radius: 19px;
    font-size: 7px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-align: center;
}







.contenedorAbierto {
    max-height: 500px;
    border: 1px solid #40404038;
    border-top: none;
    border-radius: 17px;
    opacity: 1;
    margin-bottom: 8px;
    overflow: hidden;
}

.mediacionEnProceso {
    height: 53px;
    background: #ff8f3a 0% 0% no-repeat padding-box;
    border-radius: 17px 17px 0 0;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    margin: 0;
}

.mediacionSinProceso {
    height: 53px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #5e5e5e38;
    border-radius: 17px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    margin-bottom: 8px;
}

.tituloAcordeonNaranja {
    text-align: left;
    font: normal normal bold 18px/31px Manrope;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin: 0;
}

.tituloAcordeonTransparente {
    text-align: left;
    font: normal normal bold 18px/31px Manrope;
    letter-spacing: 0px;
    color: #131313;
    opacity: 1;
    margin: 0;
}

.tituloAcordeonGrisNaranja {
    text-align: left;
    font: normal normal normal 16px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin: 0;
    width: 280px;
}

.tituloAcordeonGrisBlanco {
    text-align: left;
    font: normal normal normal 16px/20px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin: 0;
    width: 280px;
}

.labelMediacion {
    text-align: left;
    font: normal normal normal 11px/18px Manrope;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.btnActAzul {
    height: 25px;
    background: #0043FF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}

.btnActNaranja {
    height: 25px;
    background: #FF873F 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}

.btnActVerde {
    height: 25px;
    background: #47BC82 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}

.btnActRojo {
    height: 25px;
    background: red 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 13px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
}