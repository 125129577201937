.sectionNombreExpediente {
    background: transparent linear-gradient(180deg, #0772FF 0%, #1461C5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #00000029;
    color: white;
}

.fondoMiniTablaRojo {
    background-color: #FE316E;
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.titleMiniTabla {
    font-size: 14px;
    font-weight: bold;
}

.textMiniTabla {
    font-size: 19px;
    font-weight: bold;
}

.btnInfoActivo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
}

.btnInfoActivoExp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
    border-radius: 20px 0px 0px 0px;
}

.btnInfoActivoReq {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
    border-radius: 0px 20px 0px 0px;
}

.btnInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
}

.btnInfoExp {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
}

.btnInfoReq {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
}

.btnCambioPlanilla {
    border: none;
    background: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.8px;
    color: #FFFFFF;
}

.btnCambioPlanillaInactivo {
    border: none;
    background: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.8px;
    color: #F99946;
}

.btnCambioPlanilla1366 {
    border: none;
    background: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.8px;
    color: #0731FF;
}

label {
    text-align: left;
    font-size: 12px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: 0px;
    color: #0731FF;
}


.fondoMiniTabla {
    background-color: white;
}


.expedienteInfoContainer {
    background-color: white;
    border-radius: 20px;
    height: 570px;
}


.miniExpContainer {
    position: absolute;
    left: 3%;
    width: 48%;
    height: 132px;
    margin-top: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #0000000F;
    border-radius: 25px;
    padding-right: 30px;
    padding-top: 48px;
}

.infoExpContainer {
    height: 560px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #0000000F;
    border: 1px solid #0019BB38;
    border-radius: 25px;
}

.infoExpContainerAnclado {
    height: 560px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D5D5;
    border-radius: 28px;
    opacity: 1;
}



.sectionAmpliaciones {
    height: 380px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    border-radius: 26px;
    opacity: 1;
}
.sectionAmpliaciones375 {
    min-height: 50px;
    max-height: 250px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    border-radius: 26px;
    opacity: 1;
    width: 100%;
}
.sectionAmpliacionesPas {
    height: 550px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    border-radius: 26px;
    opacity: 1;
}

.divTituloAmpliaciones {
    height: 44px;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #0019BB38;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
}

.divTituloAmpliaciones375 {
    height: 30px;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #0019BB38;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
    padding-left: 5%;
}

.tituloAmpliaciones {
    color: #F99946;
    font-size: 16px;
    font-weight: 900;
    font-style: italic;
}
.tituloAmpliaciones375 {
    color: #F99946;
    font-size: 12px;
    font-weight: 900;
    font-style: italic;
}
.registroSiniestralidadTitulo {
    color: #F99946;
    font-size: 16px;
    font-weight: 900;
    font-style: italic;
}
.registroSiniestralidadTitulo375 {
    color: #F99946;
    font-size: 12px;
    font-weight: 900;
    font-style: italic;
}
.sectionItemsAmpliaciones {
    padding-left: 20px;
}


.labelCheckboxAmpliaciones {
    padding-left: 10px;
    color: #20242D;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
}

.labelCheckboxAmpliaciones375 {
    padding-left: 5px;
    color: #20242D;
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
}

.botonEnviarAmpliacion {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 11px;
    border: none;
    height: 31px;
    font-size: 12px;
}

.botonEnviarAmpliacion375 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 11px;
    border: none;
    height: 20px;
    font-size: 10px;
    color: #FFFFFF;
}

.pbtn {
    text-align: left;
    font-size: 11px;
    font-weight: 900;
    color: #FFFFFF;
    text-align: center;
}

.agregarExpPasContainer {
    width: 85%;
    height: 80vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #0000000F;
    border: 1px solid #0019BB38;
    border-radius: 25px;
}

.btnCambiarPestaniaActivo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    width: 100%;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
}

.btnCambiarPestaniaActivoExp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    width: 100%;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
    border-radius: 20px 0px 0px 0px;
}

.btnCambiarPestaniaActivoReq {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    width: 100%;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    background: #E9F3FF 0% 0% no-repeat padding-box;
    border: 1px solid #0019BB38;
    opacity: 1;
    border-radius: 0px 20px 0px 0px;
}

.btnCambiarPestania {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 51px;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
}

.btnCambiarPestaniaExp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 51px;
    border-bottom: 1px solid #0019BB38;
    border-right: 1px solid #0019BB38;
}

.btnCambiarPestaniaReq {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 51px;
    border-left: 1px solid #0019BB38;
    border-bottom: 1px solid #0019BB38;
}

.contenedorPestaniaAgregarExp {
    padding-left: 47px;
    padding-top: 43px;
}

.tituloPestania {
    font-size: 25px;
    color: #464A53;
}

.subtituloPestania {
    font-size: 19px;
    font-weight: 100;
    font-style: italic;
    color: #464A53;
}

.labelPestania {
    padding-top: 9px;
    font-size: 20px;
    font-weight: normal;
    color: #464A53;
}

.labelPestaniaDesconoce {
    font-size: 16px;
    font-weight: normal;
    color: #464A53;
    text-align: center;
    
}

.divAdjuntos {
    height: 200px;
    width: 100%;
    border: 2px dashed #CCCCCC;
    opacity: 1;
    cursor: pointer;
}

.divAdjuntosPas {
    height: 120px;
    border: 2px dashed #CCCCCC;
    opacity: 1;
}

.divAdjuntosLes {
    height: 200px;
    width: 100%;
    border: 2px dashed #CCCCCC;
    opacity: 1;
    cursor: pointer;
}

.divAdjuntosVacio {
    margin: 10px;
    height: 140px;
    width: 100%;
    opacity: 1;
}

.iconUpload {
    font-size: 30px;
}

.limitePestania {
    font-size: 14px;
    font-weight: 100;
    color: red;
    font-style: italic;
}

.textareaAmpliacion {
    height: 500px;
    background-color: white;
}


.letraPlanillaExpediente {
    color: #20242D;
    font-size: 11px;
    font-weight: 500;
}





.registroSiniestralidadHeaderMovil {
    font-size: 10px;
}