html,
body {
  width: 100vw;
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  padding: 0px;
  box-sizing: border-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #F99946;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5DA1F9;
}


.mainTabla {
  padding-top: 100px;
  padding-left: 1%;
  padding-right: 1%;
  display: grid;
  grid-template-columns: 75% 25%;
  gap: 20px;
}

.mainTicket {
  padding-top: 120px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerTituloExp {
  display: flex;
}

.elementosTituloExp {
  margin-right: 20px;
}



.elementosTituloExp2 {
  margin-right: 20px;
}


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

.subtituloTituloExp {
  color: #7A86A1;
  font-size: 12px;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-wrap: nowrap;
}

.textoSubtituloExp {
  color: #7A86A1;
  font-size: 14px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  margin: 0;
}

.textoSubtituloExp375 {
  color: #7A86A1;
  font-size: 12px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  margin: 0;
}

.subtituloTituloExp768 {
  color: #7A86A1;
  font-size: 14px;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  text-wrap: nowrap;
}

.textoSubtituloExp768 {
  color: #7A86A1;
  font-size: 16px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  margin: 0;
}

