@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.mainNovedades2025 {
    padding-top: 80px;
    padding-left: 2%;
}

.tituloNovedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 28px;
    color: #0F1727;
}

.subtituloNovedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #808897;
}

.botonFiltroNovedades2025 {
    width: 300px;
    height: 140px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    opacity: 1;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    cursor: pointer;
}

.botonFiltroNovedades2025Activo {
    width: 300px;
    height: 140px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #F8904A;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    opacity: 1;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    cursor: pointer;
}

.textoFiltro1Novedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 23px;
    color: #0F1727;
    text-align: left;
}

.cantidadFiltro1Novedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 44px;
    color: #0F1727;
    text-align: left;
    padding-left: 16px;
}

.subtextoFiltro1Novedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #808897;
    text-align: left;
}

.ultimoTextoFiltro1Novedades2025 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #515151;
    text-align: left;
}


.progress-container {
    height: 20px !important;
    border-radius: 10px;
    overflow: hidden;
    background-color: white !important;
}

.progress-segment {
    margin-right: 4px;
}

.last-segment {
    margin-right: 0 !important;
}

.nivel1-bg {
    background-color: #00E676 !important;
}

.nivel2-bg {
    background-color: #FFC107 !important;
}

.nivel3-bg {
    background-color: #FF5252 !important;
}

.vencimiento1-bg {
    background-color: #93A7DE !important;
}

.vencimiento2-bg {
    background-color: #7BDCB5 !important;
}

.vencimiento3-bg {
    background-color: #FE6851 !important;
}

.color-box {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    display: inline-block;
}

.nroProgressBar {
    margin-left: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #0F1727;
    text-align: left;
}

.textoReferenciaProgresoNivel {
    margin-left: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #808897;
    text-align: left;
}


.contenedorFiltroNovedades2025 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBD8D8;
    border-radius: 8px;
    opacity: 1;
    height: 80vh;
    position: sticky;
    top: 100px;
}

.botonFiltrarNovedades2025 {
    background: transparent linear-gradient(270deg, #F98D4E 0%, #F9AB39 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 7px;
    border: none;
    opacity: 1;
    width: 141px;
    height: 46px;
    font: normal normal bold 16px/24px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.botonVaciarNovedades2025 {
    width: 141px;
    height: 46px;
    background: transparent linear-gradient(270deg, #DFDFDF 0%, #E8E8E8 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 16px 40px #695F9724;
    border-radius: 10px;
    opacity: 1;
    border: none;
    font: normal normal bold 16px/24px Poppins;
    letter-spacing: 0px;
    color: #4694F9;
}

.inputsNovedades2025 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    height: 39px;
    padding-left: 10px;
    font: normal normal bold 11px/14px Poppins;
    color: #6A6C71;
}

.customMultiselectNovedades2025 .dropdown-container {
    background: #FFFFFF !important;
    border: 0.5px solid rgba(112, 112, 112, 0.5) !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 39px !important;
    padding-left: 10px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: bold !important;
    font-size: 11px !important;
    line-height: 14px !important;
    color: #6A6C71 !important;
    box-shadow: none !important;
    /* Elimina cualquier sombra */
    display: flex !important;
    align-items: center !important;
}

/* Ajustar el encabezado (el texto y el icono de desplegable) */
.customMultiselectNovedades2025 .dropdown-heading {
    background: transparent !important;
    padding: 10px !important;
    display: flex !important;
    align-items: center !important;
    border: none !important;
    /* Elimina bordes extra */
}

/* Asegurar que no haya doble borde inferior */
.customMultiselectNovedades2025 .dropdown-container::after {
    content: none !important;
}

/* Ajustar el borde cuando está activo */
.customMultiselectNovedades2025 .dropdown-container:focus-within {
    border: 0.5px solid rgba(112, 112, 112, 0.8) !important;
}

.resultadosNovedades2025 {
    font: italic normal bold 17px/31px Poppins;
    letter-spacing: 0px;
    color: #6A6C71;
}



.custom-checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Contenedor visual del checkbox */
  .checkbox-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px; 
    height: 20px;
    border: 2px solid #F98D4E; 
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.8s ease;
  }
  
  /* Efecto hover */
  .checkbox-label:hover {
    border-color: #e87c3e;
  }
  
  .custom-checkbox:checked + .checkbox-label {
    background-color: #F98D4E;
    border-color: #F98D4E;
  }
  
  /* Check animado */
  .custom-checkbox:checked + .checkbox-label::after {
    content: "";
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.2s ease;
  }
  

  .textoSelectAllNov2025 {
    font-family: 'Georgia', serif; 
    font-style: italic; 
    font-weight: 400; 
    font-size: 14px;
    color: #666;
    text-align: center; 
    letter-spacing: 0.5px; 
    transition: color 0.3s ease; 
  }
  
  .textoSelectAllNov2025:hover {
    color: #333;
  }
  